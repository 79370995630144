import React from 'react';

import Layout from '../../components/layout';
import './index.sass';
import SEO from '../../components/seo';
import { config } from '../../config';
import { Disqus } from 'gatsby-plugin-disqus';
import { graphql } from 'gatsby';
import { Link as GLink } from 'gatsby';

const Index = ({ data }) => {
  const disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl + '/employee-directory-support'}`,
    identifier: 'employee-directory-support',
    title: 'Employee Directory Support',
  };

  return (
    <Layout pageType="blog">
      <SEO
        keywords={[
          'sharepoint',
          'employee',
          'directory',
          'online',
          'spfx',
          'webpart',
        ]}
        title="Node Vision | Employee Directory for SharePoint Online"
      />
      <div className="employee-directory-support">
        <section className="heading">
          <h1>Employee Directory Support</h1>
        </section>
        <section className="support">
          <div className="container">
            <div className="download-text">
              Download a full version of the Employee Directory.
            </div>
            <a
              href="/download/node-vision-employee-directory.sppkg"
              className="is-button"
              onClick={() => {
                ga('send', 'event', {
                  eventCategory: 'employee-directory',
                  eventAction: 'download',
                  eventLabel: 'file download',
                  eventValue: 1,
                });
                return true;
              }}
            >
              Download
            </a>
            <div className="current-version">
              Current version: {config.employeeDirectoryVersion}
              <br />
              <br />
              <a href="/employee-directory-change-log" className="link">
                Change log
              </a>
            </div>
            <div className="instructions">
              <a
                href="https://youtu.be/L2HIyL0Zx-w"
                className="link"
                target="_blank"
              >
                Installation instructions
              </a>
              <a
                href="https://youtu.be/3VSKytOonjw"
                className="link"
                target="_blank"
              >
                Upgrade instructions
              </a>
              <a
                href="https://youtu.be/7Q76bgBkBJI"
                className="link"
                target="_blank"
              >
                Product activation instructions
              </a>
              <a
                href="https://youtu.be/LyVLqlW6mfE"
                className="link"
                target="_blank"
              >
                Customization guide - editing template
              </a>
            </div>
            <div className="supported-environments">
              The current version is compatible with{' '}
              <strong>SharePoint Online</strong> (both Classic and Modern pages)
              and the following browsers: Google Chrome, Microsoft Edge,
              Firefox, Safari. The Internet Explorer is no longer supported.
              <br />
              <br />
              For other SharePoint versions (2016 & 2019), please email us at{' '}
              <a href="mailto:info@nodevision.com.au" className="link">
                info@nodevision.com.au
              </a>
              .
              <br />
              <br />
              For any other question, please contact us below or through our{' '}
              <GLink to="/contact" className="link">
                contact form
              </GLink>.
            </div>
          </div>
        </section>

        <section>
          <div className="container disqus-wrapper">
            <Disqus config={disqusConfig} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query EmployeeDirectorySupport {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
